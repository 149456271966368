<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/web/intro/ico_char02.svg')" /></div>

			<div class="text_guide pt-20">
				<h3>{{  text_title }}</h3>
			</div>
			<div class="text_guide_desc pt-15 color-gray">

				<p class="error">{{  text_content }}</p>

			</div>
		</div>


		<div class="intro_btn_wrap mb-20">
			<div class="btn btn_area">
				<button class="btn_l btn_fill_blue" @click="toNext">확인</button>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: '401'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '인증 토큰 만료'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
			, type: this.$route.params.type
		}
	}
	, computed: {
		text_title: function(){
			let t = '인증 토큰 만료'

			return t
		}
		, text_content: function(){
			let t = '로그인 후 다시 이용해주세요'
			return t
		}
		, text_button: function(){

			let t = '확인'
			return t
		}
	}
	,methods: {
		toNext: async function() {
			sessionStorage.clear()
			document.location.href = '/'
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>
<style>
h3 {
	color : white
}

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }


.btn { display: inline-block; width: 100%; text-align: center; padding: 10px; font-size: 16px; color: white;}
.btn_l {
	display: inline-block;
	height: 3.5rem;
	line-height: 1rem;
	min-width: 400px;
	padding: 0 3rem;
	border-radius: 5rem;
	border-style: solid;
	border-width: 0.1rem;
	box-sizing: border-box;
	font-weight: 400;
	font-size: 1.1rem;
	text-align: center;
	box-sizing: border-box;
}
</style>